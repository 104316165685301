import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaRegComments, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { blogs } from "../data";
import axios from "axios";

const BlogAreaOne = () => {
  const [dynamicBlogsData, setDynamicBlogData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.post(
          "http://139.135.52.123:4021/Blogs/getWebsiteBlog",
          { domainId: 27, apiKey: "amqnwb@1019" }
        );
        setDynamicBlogData(response?.data?.result);
      } catch (error){}
    };
    getData();
  }, []);

  return (
    <>
      {/*===================== Blog Area One start =====================*/}
      <div id="blog" className="blog-area  pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">News/Blog</h6>
            <h2 className="title">
              Stay Updated with Our Latest <span>Insights</span>
            </h2>
          </div>
          <div className="row">
            {dynamicBlogsData.map((blog, index) => {
              return (
                <Link
                  to={`/blogs/${blog?.id}`}
                  key={index}
                  className="col-lg-4 col-md-6"
                >
                  <div className="single-blog-list">
                    <div className="thumb">
                      <img src={blog?.image} alt="img" />
                    </div>
                    <div className="details">
                      <p className="date mb-3">
                        <FaCalendarAlt />
                        {new Date(blog?.updatedAt).toDateString()}
                      </p>
                      <h5>
                        <Link to={`/blogs/${blog?.id}`}>{blog?.title}</Link>
                      </h5>
                      <div className="meta">
                        <div className="row">
                          <div className="">
                            <p>
                              <FaRegUser />
                              {blog?.createdBy?.firstName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {/* ===================== BlogAreaOne End =====================*/}
    </>
  );
};

export default BlogAreaOne;
