import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div id="about-us" className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-1 top_image_bounce"
                  src="assets/img/about/2.png"
                  alt="img"
                />
                <img
                  className="animate-img-2 left_image_bounce"
                  src="assets/img/about/3.png"
                  alt="img"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                />
                <img className="main-img" src="/images/about.gif" alt="img" />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  Revolutionizing <span>IT Solutions</span> for Modern
                  Businesses
                </h2>
                <p className="content mb-4 mb-xl-5">
                  Software Fun is committed to delivering state-of-the-art IT
                  solutions that empower businesses to excel in the digital
                  world. Our focus on innovation, quality, and customer
                  satisfaction sets us apart as a leader in the IT industry.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/2.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Our Vision</h5>
                        <p>
                          Our vision is to transform the IT landscape by
                          providing exceptional software solutions that drive
                          business success. We aim to be at the forefront of
                          technological advancements, enabling our clients to
                          harness the full potential of IT.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/3.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Our Mission</h5>
                        <p>
                          Our mission is to deliver reliable, scalable, and
                          innovative IT solutions that cater to the unique needs
                          of our clients. We are committed to excellence and
                          strive to exceed expectations through our dedicated
                          service and expertise.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
