import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavBar = ({ isScrolled }) => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);

  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
  };


  return (
    <>
      {/* search popup start*/}
      <div
        className={`${
          searchShow ? "td-search-popup active" : "td-search-popup "
        } main-header`}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id="body-overlay"
      ></div>
      {/* navbar start */}
      <nav className="navbar navbar-area navbar-area_1 navbar-expand-lg">
        <div id="nav_container" className="container nav-container navbar-bg">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link to="/">
              <img
                src="/logo.png"
                style={{ height: 50, width: 50 }}
                alt="img"
              />
            </Link>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li className="">
                <HashLink smooth to="/" onClick={() => window.scrollTo(0, 0)}>
                  Home
                </HashLink>
                {/* <Link to="/">Home</Link> */}
              </li>
              <li className="">
                <HashLink smooth to="/#about-us">
                  About us
                </HashLink>
                {/* <a href="/#about-us">About Us</a> */}
              </li>
              <li className="menu-item-has-children">
                <HashLink smooth to="/#our-services">
                  Service
                </HashLink>
              </li>
              <li className="menu-item-has-children mega-menu">
                <HashLink smooth to="/#faq">
                  Faq
                </HashLink>
              </li>

              <li className="menu-item-has-children">
                <HashLink smooth to="/#blog">Blog</HashLink>
              </li>
              <li>
                <HashLink smooth to="/#contact">
                  Contact Us
                </HashLink>
              </li>
            </ul>
          </div>

        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavBar;
