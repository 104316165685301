import React, { useEffect, useState } from "react";
import BlogDetailsArea from "../components/BlogDetailsArea";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { useLocation } from "react-router-dom";
import { blogs } from "../data";
import axios from "axios";

const BlogDetails = () => {
  const id = useLocation().pathname.split("/").slice(-1)[0];
  const [Post, setPost] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      try {
        const response = await axios.post(
          "http://139.135.52.123:4021/Blogs/getSingleblog",{blogId:id,apiKey:"amqnwb@1019"}
        );
        setPost(response?.data?.result);
      } catch (error) {}
    };
    getData();
  }, []);

  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={Post?.title} link={Post?.link} />

      {/* Blog Details Area */}
      <BlogDetailsArea Post={Post} />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default BlogDetails;
