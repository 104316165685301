import React from "react";
import {
  FaCalendarAlt,
  FaCheckCircle,
  FaFacebookF,
  FaRegFolderOpen,
  FaInstagram,
  FaQuoteRight,
  FaTwitter,
  FaRegUser,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { blogs } from "../data";
import parse from 'html-react-parser';
const BlogDetailsArea = ({ Post }) => {
  return (
    <>
      {/* ==================== Blog Details Area start ====================*/}
      <div className="blog-area blog-area_details pd-top-60 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-page-content">
                <div className="single-blog-inner">
                  <div className="thumb">
                   {Post?.image && <img src={Post?.image} alt="img" /> }
                  </div>
                  <div className="details">
                    <ul className="blog-meta">
                      <li>
                        <FaRegUser /> By {Post?.createdBy?.firstName}
                      </li>
                      <li>
                        <FaCalendarAlt /> {new Date(Post?.updatedAt).toDateString()}
                      </li>
                    </ul>
                    {Post &&  parse(`${Post?.description}`)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Blog Details Area End ====================*/}
    </>
  );
};

export default BlogDetailsArea;
