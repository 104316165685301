let serviceList = [
  {
    title: "Web Development",
    des: "At Software Fun, we offer comprehensive web development services to create robust, scalable, visually appealing websites. Our expert team ensures your online presence stands out and meets your business goals effectively, delivering exceptional performance and high-quality results.",
    img: "/icons/web.png",
  },
  {
    title: "Mobile Development",
    des: "Our mobile development services are tailored to bring your app ideas to life. We specialize in creating user-friendly, high-performance mobile applications that provide a seamless experience across all devices for optimal satisfaction.",
    img: "/icons/Mobile-development.png",
  },
  {
    title: " Blockchain Development",
    des: " Harness the potential of blockchain technology with our innovative solutions. At Software Fun, we develop secure, transparent, decentralized applications that revolutionize how businesses operate and manage data, ensuring optimal security, efficiency, and innovative data solutions.",
    img: "/assets/img/service-icon/3.png",
  },
  {
    title: "Cloud Computing",
    des: "Leverage the power of the cloud with our cloud computing solutions. We help businesses migrate, manage, and optimize their cloud infrastructure to enhance efficiency, security, and scalability, ensuring the best possible performance and improved overall operations.",
    img: "/icons/cloud computing.png",
  },

  {
    title: "AI Solutions",
    des: "Unlock the power of artificial intelligence (AI) with our cutting-edge services. We develop intelligent systems that automate processes, provide deep insights, and enhance decision-making to drive business growth significantly, ensuring unparalleled success and innovation.",
    img: "/icons/ai solutions.png",
  },
];

export default serviceList;
