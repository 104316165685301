
import { Modal } from "flowbite-react";


export function SuccessModal({openModal,setOpenModal}) {
  

  return (

    <div>
      <Modal
        className="successToast"
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Body>
          <div className="modalMain position-relative">
          {/* <a href="#" title="Close" className="modal-close">Close</a> */}
                    <div className="modal-body text-center p-lg-4">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={60} height={60} viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#198754" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline fill="none" stroke="#198754" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                        <p className="mt-3">Thank you for contacting us. We&apos;ll respond to you as soon as possible.</p>
                </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
