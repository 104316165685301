import { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
const MeetingSchedule = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <div
      id="calendlySchedule"
      style={{ width: "100%" }}
      className="calendly  bg-[#ebecec]  w-full "
    >
      <div className="flex flex-col items-center">
        <span className="calendlyHeading">Book Your Appointment</span>
      </div>
      {isClient && (
        <InlineWidget url="https://calendly.com/hr-softwarefun-6p0j/30min" />
      )}
    </div>
  );
};
export default MeetingSchedule;
